import styled from '@emotion/styled';
import {
  accentThemes,
  AlbumProductIcon,
  AmpIcon,
  AmpIconBase,
  MasteringProductIcon,
  PlaylistPitchingIcon,
  SingleProductIcon,
  VideoCallIcon,
} from '@imus/base-ui';
import type { SubscriptionId } from 'imddata/types/entities';
import { Caption, Card, H4 } from 'imdui';
import { TextBlock } from './TextBlock';
import { InfoContentCard } from './InfoContentCard';
import { useTranslation } from 'react-i18next';
import { useAmpPrice, useCustomerFeature } from 'imddata';

const AmpCount = styled.span`
  font-feature-settings: 'cv11' on;
  font-family: Inter;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 56px */
  letter-spacing: -1.68px;
`;

const AmpCard = styled(Card)`
  width: auto;
  flex-grow: 0;
  margin: 0 auto;
  padding: 32px;
  border-radius: 20px;
  align-items: center;
  display: flex;
`;
const AmpDisplay = ({ value = 0 }: { value?: number }) => {
  return (
    <AmpCard>
      <AmpIcon width="48px" height="48px" />
      <AmpCount>{value}</AmpCount>
    </AmpCard>
  );
};
const IconDisplay = styled.div`
  ${accentThemes.tertiary}
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  --on-surface: var(--accent);
  background: var(--bg-2, #fafafa);
`;
const InfoContentRow = styled.div`
  display: flex;
  gap: 16px;
`;

const InfoContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
`;

const AmpText = styled(Caption)`
  ${accentThemes.tertiary}
  color: var(--accent);
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

const shouldShowConsultation = (sid: SubscriptionId) => {
  switch (sid) {
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
      return true;

    case 'tier-3':
    case 'tier-1':
    case 'trial-tier-2':
    case 'trial-monthly':
    case 'trial-yearly':
    case 'music-analytics':
    case 'tier-1-yearly':
    case 'tier-1-monthly':
    case 'tier-2-yearly':
    case 'tier-2-monthly':
    case 'vip':
    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
      return false;
  }
};
const shouldShowDmdAmpProducts = (sid: SubscriptionId) => {
  switch (sid) {
    case 'tier-3':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
      return false;

    case 'tier-1':
    case 'trial-tier-2':
    case 'trial-monthly':
    case 'trial-yearly':
    case 'music-analytics':
    case 'tier-1-yearly':
    case 'tier-1-monthly':
    case 'tier-2-yearly':
    case 'tier-2-monthly':
    case 'vip':
    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
      return true;
  }
};
export const CancelFlowAmps = ({
  title,
  value,
  text,
  currentSubscriptionId,
}: {
  title: string;
  value: number;
  text: string;
  currentSubscriptionId: SubscriptionId;
}) => {
  const { t } = useTranslation();
  const pitchingAsFeature = useCustomerFeature('playlist-pitching');
  const pitchingAmpPrice = useAmpPrice('playlist-pitching');
  const releaseAmpPrice = useAmpPrice('album');
  const singleAmpPrice = useAmpPrice('single');
  const masteringAmpPrice = useAmpPrice('mastering');
  const personalConsultationAmpPrice = useAmpPrice('phone-consultation');
  return (
    <>
      <AmpDisplay value={value} />
      <TextBlock title={title} subtitle={text} />
      <InfoContentCard>
        {shouldShowDmdAmpProducts(currentSubscriptionId) && (
          <>
            <div>
              <InfoContentRow>
                <IconDisplay>
                  <SingleProductIcon></SingleProductIcon>
                </IconDisplay>
                <InfoContentText>
                  <H4>{t('release-a-single')}</H4>
                  <AmpText>
                    <AmpIconBase width={12} height={12}></AmpIconBase>
                    {singleAmpPrice}
                  </AmpText>
                </InfoContentText>
              </InfoContentRow>
            </div>

            <div>
              <InfoContentRow>
                <IconDisplay>
                  <AlbumProductIcon></AlbumProductIcon>
                </IconDisplay>
                <InfoContentText>
                  <H4>{t('release-an-album')}</H4>
                  <AmpText>
                    <AmpIconBase width={12} height={12}></AmpIconBase>
                    {releaseAmpPrice}
                  </AmpText>
                </InfoContentText>
              </InfoContentRow>
            </div>
          </>
        )}
        <div>
          <InfoContentRow>
            <IconDisplay>
              <MasteringProductIcon></MasteringProductIcon>
            </IconDisplay>
            <InfoContentText>
              <H4>{t('master-a-track')}</H4>
              <AmpText>
                <AmpIconBase width={12} height={12}></AmpIconBase>
                {masteringAmpPrice}
              </AmpText>
            </InfoContentText>
          </InfoContentRow>
        </div>
        {!pitchingAsFeature && (
          <div>
            <InfoContentRow>
              <IconDisplay>
                <PlaylistPitchingIcon />
              </IconDisplay>
              <InfoContentText>
                <H4>{t('get-playlist-pitching')}</H4>
                <AmpText>
                  <AmpIconBase width={12} height={12}></AmpIconBase>
                  {pitchingAmpPrice}
                </AmpText>
              </InfoContentText>
            </InfoContentRow>
          </div>
        )}
        {shouldShowConsultation(currentSubscriptionId) && (
          <div>
            <InfoContentRow>
              <IconDisplay>
                <VideoCallIcon />
              </IconDisplay>
              <InfoContentText>
                <H4>{t('book-personal-consultation')}</H4>
                <AmpText>
                  <AmpIconBase width={12} height={12}></AmpIconBase>
                  {personalConsultationAmpPrice}
                </AmpText>
              </InfoContentText>
            </InfoContentRow>
          </div>
        )}
      </InfoContentCard>
    </>
  );
};
