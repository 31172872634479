import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Clickable } from 'imdui';

const Option = styled(Clickable)<{ selected: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 68px;
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 16px;
  ${({ selected }) =>
    selected
      ? `
box-shadow: 0 0 0 2px var(--accent);
`
      : `

box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
`}
`;

export const NewPaymentOption = styled.div<{ selected: boolean }>`
  ${({ selected }) =>
    selected
      ? `

${Option} {

           box-shadow: none; margin: 0; border-radius: 0;
}

border-radius: 8px;
box-shadow: 0 0 0 2px var(--accent);
`
      : `

`}
`;

export const PaymentOption = ({
  children,
  checked,
  onCheck,
  style,
}: {
  onCheck: (v: boolean) => void;
  checked?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  const handleClick = useCallback(() => {
    onCheck(!checked);
  }, [checked]);
  return (
    <Option style={style} onClick={handleClick} selected={!!checked}>
      {children}
    </Option>
  );
};
