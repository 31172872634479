import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from '@emotion/styled';
import {
  InfoValueDisplay,
  ReleasePageOptions,
  useProductPage,
  useProductPageActions,
} from 'components';
import {
  Window,
  Card,
  Button,
  AutosizeGrid,
  OverlineText,
  HelpWindowContext,
  FieldCardAction,
  FieldCardSection,
  TextFormatted,
} from 'imdui';
import WideAmpSvg from './wideAmp.svg';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { DBNested } from 'imddata';
import {
  useBundle,
  useCustomerFeature,
  useRelease,
  useUpdateEntity,
} from 'imddata';
import {
  ReleaseDetails,
  AlertBox,
  useSubscriptionUpsell,
  EntityTargets,
  ProductTwoColumnsWrapper,
  useProductPriceFormatted,
  useProductPriceFormatter,
  BuyOrSubOffer,
  useOpenTier2UpsellContext,
} from 'imdshared';
import { useSelector } from 'react-redux';
import { ReleasePageOptionsTemplateContext } from 'components/organisms/ReleasePageOptions';

import type { ReduxState } from 'redux/reducers';
import {
  ProductPriceContext,
  useReleaseDeliveryOrder,
  ReleasePageTemplatePreview,
} from '../../../../shared';
import { useHistory } from 'react-router';
import { BodyM, ContentM, ContentS, H4 } from '@imus/services-ui/src/Text';
import { BaseGrid, Separtor } from '@imus/base-ui';
import { Portal } from 'react-portal';
import { EmailSupportToggle } from './EmailSupportToggle';
import { PitchingTrackSelector } from './PitchingTrackSelector';
import { useFeature } from 'imdfeature';
import { diff } from 'deep-object-diff';

const WindowContent = styled.div`
  padding: 0 32px 32px;
  display: grid;
  gap: 16px;
  flex-direction: column;
`;

const supportedShops = [
  '7digital',
  'applemusic',
  'youtubemusic',
  'beatport',
  'spotify',
  'youtube',
  'deezer',
  'amazonmusic',
  'amazonmusicdownload',
  'itunes',
  'traxsource',
  'qobuz',
  'qobuzdownload',
  'claromusica',
  'kkbox',
  'anghami',
  'pandora',
  'awa',
  'tidal',
];

const mapApiShopToTemplate = (shop: string) => {
  switch (shop) {
    case 'apple_music':
      return 'applemusic';
    case 'i_tunes':
      return 'itunes';
    case 'you_tube':
      return 'youtubemusic';
    case 'amazon':
      return 'amazonmusic';
    case '7_digital':
      return '7digital';
    default:
      return shop;
  }
};

//
const extraShops = [
  'qobuz',
  'claromusica',
  'kkbox',
  'anghami',
  'pandora',
  'awa',
  'tidal',
];

const apiShopsToTemplateShops = (shops: string[] = [], extra: boolean) => {
  return [
    ...shops
      .map(mapApiShopToTemplate)
      .filter((s) => supportedShops.indexOf(s) >= 0),
    ...(extra ? extraShops : []),
  ];
};

// TODO: Remove copy from Summary
const WaitForUploadErrorBlock = ({
  hasAllMetadataMet,
  uploadQueueCount,
}: {
  hasAllMetadataMet: boolean;
  uploadQueueCount?: number;
}) => {
  const { t } = useTranslation();
  return uploadQueueCount && hasAllMetadataMet ? (
    <AlertBox
      type={AlertBox.Type.warning}
      text={t('wait-for-tracks-upload-to-finish', {
        count: uploadQueueCount,
      })}
    />
  ) : null;
};
const Wrapper = styled.div`
  display: grid;
  gap: 24px;
`;

const Section = FieldCardSection;

const HeaderWithPriceChip = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
`;

const ReleasePageTemplatePreviewWithContext = (
  props: Omit<
    React.ComponentProps<typeof ReleasePageTemplatePreview>,
    'isPremium' | 'isBranded'
  >
) => {
  const mode = useContext(ReleasePageOptionsTemplateContext);
  return (
    <ReleasePageTemplatePreview
      {...props}
      isBranded={mode === 'pro' || mode === 'base'}
      isPremium={mode === 'pro' || mode === 'pro-plus'}
    />
  );
};

const PriceBreakdownNode = styled.div`
  display: flex;
  ${H4} {
    text-align: center;
  }
`;

const NON_PREMIUM_SHOPS = [
  'spotify',
  'deezer',
  'apple_music',
  'i_tunes',
  'yout_ube',
];

const PricePillBase = ({
  text,
  amp,
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
  text: string;
  amp: boolean;
}) => {
  return (
    <div style={style} className={className}>
      {amp ? <WideAmpSvg /> : null}

      <ContentS>{text}</ContentS>
    </div>
  );
};

const mapSortSplit = (
  rs: Array<{ revenueSplitTargetId: string; share: number }> = []
) => {
  return rs
    .map(({ revenueSplitTargetId, share }) => ({ revenueSplitTargetId, share }))
    .sort((a, b) =>
      a.revenueSplitTargetId > b.revenueSplitTargetId
        ? 1
        : b.revenueSplitTargetId > a.revenueSplitTargetId
          ? -1
          : 0
    );
};
const diffSplitsCount = (dbr: DBNested['releases'][number] | undefined) => {
  if (!dbr) return { diffedCount: 0, defaultCount: 0 };
  const { volumes, revenueSplits } = dbr.release;
  // const allTrackSplits = volumes.reduce<Array<Array<{share: number, revenueSplitTargetId: string}>>>((acc, v) => [...acc, ...v.map(t => t.re)],[])
  const allTrackSplits = volumes.reduce<
    Array<Array<{ share: number; revenueSplitTargetId: string }>>
  >(
    (acc, v) => [
      ...acc,
      ...v.map((t) => mapSortSplit(t?.track?.revenueSplits)),
    ],
    []
  );
  const releaseSplit = mapSortSplit(revenueSplits);

  const diffedCount = allTrackSplits
    .map((ts) => diff(ts, releaseSplit))
    .reduce(
      (acc, diffResult) => (Object.keys(diffResult).length > 0 ? acc + 1 : acc),
      0
    );

  return { diffedCount, defaultCount: allTrackSplits.length - diffedCount };
};

const PricePill = styled(PricePillBase)`
  display: flex;
  overflow: hidden;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  ${ContentS} {
    color: var(--fg-1);
  }
  border: 1px solid var(--fg-2, rgba(0, 0, 0, 0.65));
`;

export default function AdditionalFeatures({
  releaseId,
  bundleId,
}: {
  releaseId: number | string;
  bundleId: number | string;
}) {
  const { t } = useTranslation();

  const { entry: release } = useRelease({ id: releaseId });
  const { entry: bundle, reload } = useBundle({ id: bundleId });
  const { updateEntry: updateBundle } = useUpdateEntity({
    entity: 'deliveryBundles',
    id: bundleId,
  });
  const { price, recalculate } = useContext(ProductPriceContext);
  const hasSupportFeature = useCustomerFeature('customer-support');
  const hasRevenueSplitsFeature = useCustomerFeature(
    'artist-hub-pro-unbranded'
  );

  const [revenueSplitsEnabled] = useFeature({ featureKey: 'revenue-splits' });
  useEffect(() => {
    reload();
    recalculate();
  }, []);
  const {
    verifyingSteps,
    validSteps,
    state: { data: { staticReleasePage, advancedSupportPurchase } = {} },
  } = useProductPage();
  const { changeProduct } = useProductPageActions();

  const templateShops = useMemo(() => {
    const bundleShops = bundle?.shops?.map((s) => s.shopId);

    if (staticReleasePage === 'free') {
      return apiShopsToTemplateShops(
        NON_PREMIUM_SHOPS.filter((s) =>
          bundleShops ? bundleShops.indexOf(s) >= 0 : true
        ),
        false
      );
    }

    return apiShopsToTemplateShops(bundleShops, true);
  }, [bundle?.shops, staticReleasePage]);

  const uploadQueueCount = useSelector(
    (state: ReduxState) =>
      state.requests.entities.tracks.uploadQueue &&
      state.requests.entities.tracks.uploadQueue.length
  );

  const priceFormatter = useProductPriceFormatter();

  // const isOrderValid = validSteps;

  const { open: openUpsell } = useSubscriptionUpsell();

  const handleUpsellOnReleasePage = useCallback(() => {
    openUpsell({
      section: 'promo-tools',
      analytics: { detail: 'release-page' },
    });
  }, []);

  const { startOrder, ordering } = useReleaseDeliveryOrder({
    bundleId,
    releaseId,
  });

  // TODO: remove to debug
  const devmode = useSelector((state: ReduxState) => state.dev.devmode);

  const enabledFeatures = useSelector(
    ({ auth }: ReduxState) => auth.profile?.extra?.featuresEnabled
  );

  const showHelpWindow = useContext(HelpWindowContext);
  const cidTracksCount = bundle?.tracks.reduce(
    (acc, bt) => (bt.addToContentId ? acc + 1 : acc),
    0
  );
  const history = useHistory();
  const tier2Context = useOpenTier2UpsellContext();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const supportPrice = useProductPriceFormatted('customer-support');
  const enableYouTubeContentId = bundle?.enableYouTubeContentId;
  const pitchedTrackId = bundle?.tracks.find(
    (bt) => bt.playlistPitchingStatus === 'requested'
  )?.trackId;

  const pitchingTrackMissing =
    !pitchedTrackId && bundle?.enableEditorialPlaylistPitching;

  const handlePitchingUpdate = useCallback(
    (id) => {
      const currentlyPitched = bundle?.tracks.find((bt) => bt.trackId === id);
      const previouslyPitched = bundle?.tracks.find(
        (bt) => bt.playlistPitchingStatus === 'requested'
      );

      updateBundle(
        {
          data: {
            tracks: [
              ...(bundle?.tracks.filter(
                (bt) =>
                  bt.trackId !== previouslyPitched?.trackId && bt.trackId !== id
              ) || []),
              ...(currentlyPitched
                ? [
                  {
                    ...currentlyPitched,
                    playlistPitchingStatus: 'requested',
                  },
                ]
                : [{ trackId: id, playlistPitchingStatus: 'requested' }]),
              ...(previouslyPitched
                ? [
                  {
                    ...previouslyPitched,
                    playlistPitchingStatus: 'not-requested',
                  },
                ]
                : []),
            ],
          },
          query: { with: 'tracks' },
          id: bundle?.id,
        },
        { debounce: 500 }
      );
    },
    [bundle?.tracks, bundle?.id]
  );

  const { diffedCount, defaultCount } = diffSplitsCount(bundle?.releases[0]);

  return (
    <>
      <div data-test-id="MusicDistribution-Summary-Data">
        <Helmet>
          <title>{t('page-title-music-distribution-summary')}</title>
        </Helmet>
        <Wrapper>
          <ProductTwoColumnsWrapper>
            <div>
              <WaitForUploadErrorBlock
                hasAllMetadataMet={!!release?.targets.hasAllMetadata.isMet}
                uploadQueueCount={uploadQueueCount}
              />
              <Card
                style={{
                  padding: '32px',
                  width: '100%',
                  flexDirection: 'column',
                }}
                secondary={true}
              >
                <BaseGrid>
                  <InfoValueDisplay
                    value={priceFormatter.format(Number(price))}
                  />
                  <Separtor />
                  <PriceBreakdownNode id="MusicDistribution-PriceBreakdown" />
                </BaseGrid>
              </Card>
            </div>
            <div>
              <Card>
                <Section>
                  <ReleaseDetails
                    release={release || undefined}
                  ></ReleaseDetails>
                  {enableYouTubeContentId && (
                    <div>
                      <OverlineText label={t('youtube-content-id')} />
                      <FieldCardAction
                        onClickHelp={() => {
                          showHelpWindow(
                            t('youtube-content-id'),
                            t('youtube-content-id-helpertext')
                          );
                        }}
                        action={
                          <Button
                            text={t('edit')}
                            onClick={() => {
                              history.push(
                                `/order/md/${bundleId}/tracks-details`
                              );
                            }}
                          />
                        }
                      >
                        <ContentM data-test-id="ContentIdTrackCount">
                          {cidTracksCount}{' '}
                          {t('track', { count: cidTracksCount })}
                        </ContentM>
                      </FieldCardAction>
                    </div>
                  )}
                  {bundle?.enableEditorialPlaylistPitching && (
                    <>
                      <PitchingTrackSelector
                        value={pitchedTrackId}
                        onSelect={handlePitchingUpdate}
                        volumes={release?.volumes}
                      />
                    </>
                  )}
                </Section>

                {revenueSplitsEnabled && hasRevenueSplitsFeature && (
                  <Section>
                    <HeaderWithPriceChip>
                      <H4>{t('revenue-splits')}</H4>
                    </HeaderWithPriceChip>

                    <div>
                      <FieldCardAction
                        onClickHelp={() => {
                          showHelpWindow(
                            t('revenue-splits'),
                            t('revenue-splits-helptext-default')
                          );
                        }}
                        action={
                          <BodyM>
                            {defaultCount} {t('track', { count: defaultCount })}
                          </BodyM>
                        }
                      >
                        <BodyM>{t('default-splits')}</BodyM>
                      </FieldCardAction>

                      <FieldCardAction
                        onClickHelp={() => {
                          showHelpWindow(
                            t('revenue-splits'),
                            t('revenue-splits-helptext-variation')
                          );
                        }}
                        action={
                          <BodyM>
                            {diffedCount} {t('track', { count: diffedCount })}
                          </BodyM>
                        }
                      >
                        <BodyM>{t('variation-splits')}</BodyM>
                      </FieldCardAction>
                    </div>
                  </Section>
                )}
                {!hasSupportFeature && (
                  <Section>
                    <HeaderWithPriceChip>
                      <H4>{t('advanced-email-support')}</H4>
                      <PricePill text={supportPrice} amp={true}></PricePill>
                    </HeaderWithPriceChip>
                    <EmailSupportToggle
                      value={!!advancedSupportPurchase}
                      onChange={(v) => {
                        changeProduct({ advancedSupportPurchase: v });
                      }}
                    />
                  </Section>
                )}
                <Section>
                  <ReleasePageOptions
                    label={t('choose-release-page')}
                    onSelect={handleUpsellOnReleasePage}
                    template={
                      <ReleasePageTemplatePreviewWithContext
                        availableShops={templateShops}
                        title={release?.defaultNameNormalized?.title || ''}
                        displayArtist={
                          release?.defaultNameNormalized?.displayArtist || ''
                        }
                        coverUrl={release?.availableCovers.export || ''}
                      />
                    }
                  />
                </Section>
              </Card>
            </div>
          </ProductTwoColumnsWrapper>
          {devmode == 'ci' ? (
            <AutosizeGrid>
              <Card>{enabledFeatures?.map((f) => <div key={f}>{f}</div>)}</Card>
              <EntityTargets
                fallback={'notfound'}
                entity="releases"
                entityId={releaseId}
                isOpen={true}
              />
              <EntityTargets
                fallback={'notfound'}
                entity="deliveryBundles"
                entityId={bundleId}
                isOpen={true}
              />
              {release?.volumes.map((v) => (
                <>
                  {v.map((t) => (
                    <>
                      <EntityTargets
                        key={t.trackId}
                        fallback={'notfound'}
                        entity="tracks"
                        entityId={t.trackId}
                        isOpen={true}
                      />
                      <EntityTargets
                        fallback={'notfound'}
                        entity="trackNames"
                        entityId={t.track.defaultName?.id}
                        isOpen={true}
                      />
                      {t.track.artists.map((ta) => (
                        <>
                          <EntityTargets
                            key={t.trackId}
                            fallback={'notfound'}
                            entity="artists"
                            entityId={ta.artistId}
                            isOpen={true}
                          />
                          <EntityTargets
                            fallback={'notfound'}
                            entity="artistNames"
                            entityId={ta.artist.defaultName?.id}
                            isOpen={true}
                          />
                        </>
                      ))}
                      {t.track.contributors.map((ta) => (
                        <>
                          <EntityTargets
                            fallback={'notfound'}
                            entity="contributors"
                            entityId={ta.contributorId}
                            isOpen={true}
                          />
                          <EntityTargets
                            fallback={'notfound'}
                            entity="contributorNames"
                            entityId={ta.contributor.defaultName?.id}
                            isOpen={true}
                          />
                        </>
                      ))}
                      {t.track.nonPerformingContributors.map((ta) => (
                        <>
                          <EntityTargets
                            fallback={'notfound'}
                            entity="contributors"
                            entityId={ta.contributorId}
                            isOpen={true}
                          />
                          <EntityTargets
                            fallback={'notfound'}
                            entity="contributorNames"
                            entityId={ta.contributor.defaultName?.id}
                            isOpen={true}
                          />
                        </>
                      ))}
                    </>
                  ))}
                </>
              ))}
            </AutosizeGrid>
          ) : null}
        </Wrapper>

        <Window
          style={{ maxWidth: '448px' }}
          isOpen={openErrorModal}
          close={() => {
            setOpenErrorModal(false);
          }}
          title={t('delivery-bundle-validation-title')}
        >
          <WindowContent>
            {bundle?.targets.order.conditions.withinArtistLimits &&
              !bundle?.targets.order.conditions.withinArtistLimits.isMet && (
                <BuyOrSubOffer
                  style={{ marginBottom: '0px', paddingBottom: '16px' }}
                  title={t('your-release-artist-are-out-subscription-limits')}
                  description={
                    <TextFormatted
                      text={t(
                        'your-release-artist-are-out-subscription-limits-desc'
                      )}
                    />
                  }
                  subscribeContext={{
                    analytics: { detail: 'artists' },
                    ...tier2Context,
                  }}
                />
              )}
            {pitchingTrackMissing && (
              <AlertBox
                text={t(
                  'pitching-track-missing-please-select-a-pitching-track'
                )}
              />
            )}

            {!validSteps && (
              <AlertBox
                text={t(
                  'some-fields-are-not-valid-please-review-them-to-distribute'
                )}
              />
            )}
            <Button
              text={t('close')}
              onClick={() => {
                setOpenErrorModal(false);
              }}
            />
          </WindowContent>
        </Window>
        <Portal node={document && document.getElementById('next-product-step')}>
          <Button
            style={{ width: '100%' }}
            position="center"
            primary={true}
            testId={`DistributeButton-${ordering || !bundle?.targets.order.isMet || verifyingSteps
                ? 'disabled'
                : 'enabled'
              }`}
            {...{
              disabled: ordering || verifyingSteps,
              onClick: () => {
                if (!bundle?.targets.order.isMet || pitchingTrackMissing) {
                  setOpenErrorModal(true);
                } else {
                  startOrder();
                }
              },
              showLoading: ordering || verifyingSteps,
              text: t('order'),
              iconColor: 'white',
            }}
          />
        </Portal>
      </div>
    </>
  );
}
