import { DashboardCard, useCurrentLocale } from 'components';
import type { CancelEvent, Page } from '../types';
import { useTranslation } from 'react-i18next';
import type { AppLocale } from 'imddata';

const getContactLink = (locale: AppLocale) => {
  switch (locale) {
    case 'it':
      return 'https://calendly.com/melani-imd/artist-label-relations';
    case 'fr':
      return 'https://calendly.com/carlos-imd/artist-label-relations';
    case 'de':
      return 'https://calendly.com/christoph-imd/artist-label-relations';
    default:
      return 'https://calendly.com/maurizio-imd/artist-label-relations';
  }
};

export const ContactSupportCard = ({
  onClose,
  style,
  page,
  requestedSubscription,
}: {
  style: React.CSSProperties;
  requestedSubscription: string;
  onClose: (e: CancelEvent) => void;
  page: Page;
}) => {
  const locale = useCurrentLocale();
  const { t } = useTranslation();
  return (
    <DashboardCard
      style={style}
      label={t('contact-customer-service')}
      onClick={() => {
        onClose({
          requestedSubscription,
          page,
          action: 'contact-support',
        });
        window.open(getContactLink(locale), '_blank');
      }}
    >
      <DashboardCard.Text>
        {t('contact-customer-service-text')}
      </DashboardCard.Text>
    </DashboardCard>
  );
};
