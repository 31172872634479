import React, { useContext } from 'react';
import { Content } from 'imdui';
import { ApplicationSettingsContext } from 'imdshared';
import { useTranslation } from 'react-i18next';
import type { EntityModels, Nil } from 'imddata';
import { DetailsRow } from '../../../../shared';
import styled from '@emotion/styled';
import { OverviewGroupLabel } from 'components';
import { ContentS } from '@imus/base-ui';

const Details = styled.div`
  display: grid;
  gap: 12px;
`;

export default function PaymentOverviewDetails({
  overview,
  paymentMethod,
}: {
  overview: EntityModels.InvoiceOverview;
  paymentMethod: 'card' | 'paypal' | Nil | null;
}) {
  const { t } = useTranslation();

  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  const currency = overview && overview.currency.id;

  const priceFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 2,
    style: 'currency',
    currency,
  });

  const paymentMethodData =
    paymentMethod === 'card' || paymentMethod === 'paypal'
      ? overview?.availablePaymentMethods[paymentMethod]
      : null;

  return (
    <Details data-test-id="PaymentGate-to-pay">
      {overview?.groups &&
        Object.values(overview.groups).map((g, idx) => {
          return (
            <DetailsRow key={idx}>
              <ContentS>
                <OverviewGroupLabel overviewGroup={g} />
              </ContentS>
              {g.value ? (
                <ContentS>{priceFormatter.format(Number(g.value))}</ContentS>
              ) : (
                <ContentS>-</ContentS>
              )}
            </DetailsRow>
          );
        })}

      {overview?.payments &&
        overview.payments.map(({ type, name, value }, i) => (
          <DetailsRow key={i}>
            <ContentS>{t(type, { defaultValue: name })}</ContentS>

            <ContentS>{priceFormatter.format(Number(-value))}</ContentS>
          </DetailsRow>
        ))}

      {overview?.discounts &&
        overview.discounts.map(({ type, name, appliedDiscount }, i) => (
          <DetailsRow key={i}>
            <ContentS>{t(type, { defaultValue: name })}</ContentS>
            <ContentS>
              {priceFormatter.format(Number(-appliedDiscount))}
            </ContentS>
          </DetailsRow>
        ))}

      <DetailsRow>
        <ContentS>{t('payment-fee')}</ContentS>
        {paymentMethod && overview ? (
          <ContentS>
            {priceFormatter.format(Number(paymentMethodData?.fee || 0))}
          </ContentS>
        ) : (
          <ContentS>-</ContentS>
        )}
      </DetailsRow>

      <DetailsRow>
        <Content>{t('amount-to-pay')}</Content>
        {paymentMethod && overview ? (
          <Content>{priceFormatter.format(Number(overview.toPay))}</Content>
        ) : (
          <Content>{paymentMethod ? t('calculating') : '-'}</Content>
        )}
      </DetailsRow>
    </Details>
  );
}
